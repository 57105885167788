import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import { baseFontStyle } from 'components/typography';
import ArrowButton from 'components/Button/ArrowButton';

export const Wrapper = styled.form`
  max-width: ${780 + 30}px;
  margin: 60px 0 60px -45px;
  padding: 0 15px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;

  ${ArrowButton} {
    margin: 30px auto 0;
  }

  .PhoneInputInput {
    height: 49px;
    margin-top: 12px;
  }

  .PhoneInputCountry {
    margin-top: 12px;
  }

  .textarea-message {
    resize: none;
    width: 100%;
    height: 115px;
  }

  textarea::placeholder {
    color: #000;
    opacity: 1;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    margin: 120px 0 120px -45px;
  }
`;

export const HalfBox = styled.div`
  flex: 1 50%;
  max-width: 100%;
  margin-bottom: 10px !important;
  padding-left: 10px;
  padding-right: 10px;

  @media ${mediaMin(breakpoint.desktop)} {
    max-width: 50% !important;
  }
`;

export const Box = styled.div`
  flex: 1 100%;
  margin-bottom: 30px;
  max-width: 100% !important;
  padding-left: 10px;
  padding-right: 10px;
  
  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 60px;
  }
  #hidden_input {
    display: none;
  }
`;


export const BoxSelect = styled.div`
  flex: 1 100%;
  margin-bottom: 30px;
  max-width: 100% !important;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0px !important;
  
  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 0px !important;
  }
  #hidden_input {
    display: none;
  }
`;

export const ErrorMessage = styled.span`
  margin: 0 auto 30px;
  min-width: 100%;
  text-align: center;
  ${baseFontStyle};
  color: #ec5990;
`;
